import {
	FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
} from "@app/Api/gen";
import { openConfirmPurchaseWithPersonalInfoModal, PersonalInfoSubmitData } from "@app/Components/Modals";
import OpenApiClient from "@app/Api/OpenApiClient";

export const handlePersonalInfoRequirements = async (
	createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult,
	compact: boolean
) => {
	if (createPaymentResult.PersonalInfoRequirements != null) {
		const piData = await new Promise<PersonalInfoSubmitData>((resolve, reject) => {
			openConfirmPurchaseWithPersonalInfoModal({
				paymentResult: createPaymentResult,
				compact,
				onSubmit: (piData) => {
					resolve(piData);
				},
				onClose: () => {
					reject(new Error("Email input cancelled"));
				}
			});
		});

		// Use StoreApi's UpdateOrderPi to send data from piData
		try {
			const updateOrderPICommand: FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand =
				{
					PublicOrderId: createPaymentResult.TransactionId,
					OrderPersonalInfo: {
						Email: piData.email || null,
						PhoneNumber: piData.phone || null
					}
				};

			await OpenApiClient.Store.fxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpoint(updateOrderPICommand);

			log.info("Personal information updated successfully");
		} catch (error) {
			log.error(`Failed to update personal information for order ${createPaymentResult.TransactionId}:`, error);
			throw new Error(
				`Failed to update personal information for order ${createPaymentResult.TransactionId}: ` + error
			);
		}
	}
};
