import React, { useEffect, useRef } from "react";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import { Button, TextInput, Stack, Text, Box, Image, ScrollArea } from "@mantine/core";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Analytics from "../../Utils/Analytics";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements,
	FxIdApplicationStoreOrderProductData,
	FxIdApplicationStorePriceData
} from "@app/Api/gen";
import { useIsMobile } from "@app/Screens/WebPlayer/Hooks";

// Schema for email and phone validation
const createSchema = (t: (key: string) => string) =>
	z.object({
		email: z
			.string()
			.email({ message: t("invalid_email_address") })
			.optional(),
		phone: z
			.string()
			.regex(/^\+?[1-9]\d{1,14}$/, { message: t("invalid_phone_number") })
			.optional()
	});

type FormValues = z.infer<ReturnType<typeof createSchema>>;

// Add this new interface
export interface PersonalInfoSubmitData {
	email?: string;
	phone?: string;
}

type PersonalInfoInputModalInnerProps = {
	onSubmit: (data: PersonalInfoSubmitData) => void | Promise<void>;
	onClose?: () => void;
	/**
	 * Если тру то не будет показана инфа кроме названия товара
	 */
	compact?: boolean;
	paymentResult: {
		PersonalInfoRequirements?: Pick<
			FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements,
			"EmailRequirement"
		> | null;
		OrderProduct: Pick<
			FxIdApplicationStoreOrderProductData,
			"LocalizedProductName" | "LocalizedProductDescription" | "ImageUrl" | "Price" | "Currency"
		>;
		PriceData: Pick<FxIdApplicationStorePriceData, "LocalizedPrice">;
	};
};

interface ModalParams extends PersonalInfoInputModalInnerProps {
	target?: string;
	analyticsData?: Parameters<(typeof Analytics)["site"]["openConfirmPurchaseWithPersonalInformation"]>[number];
}

export const ConfirmPurchaseWithPersonalInfoInput = (props: PersonalInfoInputModalInnerProps) => {
	const { t } = useTranslation();
	const schema = createSchema(t);
	const { onSubmit, onClose, paymentResult, compact } = props;
	const isMobile = useIsMobile();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setFocus
	} = useForm<FormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: paymentResult?.PersonalInfoRequirements?.EmailRequirement?.DefaultValue ?? undefined,
			phone: undefined
		}
	});

	const onSubmitForm = (data: FormValues) => {
		const submitData: PersonalInfoSubmitData = {
			email: data.email,
			phone: data.phone
		};
		onSubmit(submitData);
	};

	useEffect(() => {
		return () => {
			onClose?.();
		};
	}, [onClose]);

	React.useEffect(() => {
		setFocus("email");
	}, [setFocus]);

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Stack spacing="md">
				<TextInput
					{...register("email")}
					label={t("personal_info_modal.email_label")}
					error={errors.email?.message}
					size="lg"
					placeholder={"Email"}
					styles={(theme) => ({
						input: {
							fontSize: theme.fontSizes.lg,
							padding: theme.spacing.lg
						},
						label: {
							fontSize: theme.fontSizes.lg,
							marginBottom: theme.spacing.lg,
							marginTop: theme.spacing.lg
						}
					})}
				/>
				<Button type="submit">{t("personal_info_modal.button_text")}</Button>

				{/* NOTE: Раскоментируй если нужна кнопка с ценой */}
				{/*<Button type="submit">*/}
				{/*	{t("store.buy_for_price", { price: paymentResult.PriceData.LocalizedPrice })}*/}
				{/*</Button>*/}

				<Box>
					<Text weight={700}>{paymentResult.OrderProduct.LocalizedProductName}</Text>
					{compact != true && (
						<>
							<Text>{paymentResult.OrderProduct.LocalizedProductDescription}</Text>
							<Image src={paymentResult.OrderProduct.ImageUrl} />
						</>
					)}
				</Box>
				{/*{paymentResult?.PersonalInfoRequirements?.Pho && (*/}
				{/*	<TextInput*/}
				{/*		label={t("personal_info_modal.phone_label")}*/}
				{/*		error={errors.phone?.message}*/}
				{/*		{...register("phone")}*/}
				{/*	/>*/}
				{/*)}*/}
			</Stack>
		</form>
	);
};

export const ConfirmPurchaseWithPersonalInfoInputModal = ({
	context,
	id,
	innerProps
}: ContextModalProps<PersonalInfoInputModalInnerProps>) => {
	const handleSubmit = async (data: PersonalInfoSubmitData) => {
		try {
			await innerProps.onSubmit(data);
			closeModal(id);
		} catch (error) {
			// Handle any errors that occur during submission
			log.error("Error submitting personal info:", error);
			// Optionally, you can show an error message to the user here
		}
	};

	return <ConfirmPurchaseWithPersonalInfoInput {...innerProps} onSubmit={handleSubmit} />;
};

export const openConfirmPurchaseWithPersonalInfoModal = (props: ModalParams) => {
	const { target, analyticsData, onClose, ...innerProps } = props;

	void Analytics.site.openConfirmPurchaseWithPersonalInformation?.({ ...analyticsData });

	return openContextModal({
		modal: "confirmPurchaseWithPersonalInfoModal",
		title: i18next.t("login_from_email.message"),
		innerProps: { ...innerProps, onClose },
		styles: (theme) => ({
			header: { marginBottom: theme.spacing.md },
			title: { fontWeight: 700 }
		}),
		size: "sm",
		target,
		centered: true
	});
};
